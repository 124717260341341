.secondGrid {
  height: 100%;
  display: flex;
  padding: 10px 14px;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #1f4771;
}

.iconstyle {
  height: 30px;
  width: 30px;
  display: flex;
  cursor: pointer;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  background-color: #1f4771;
  box-shadow: 0px 3px 6px #00000052;
}

.maindiv {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 30%;
  left: -14%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 4px 5px #0000004d;
}

.divdesign {
  height: 100%;
  width: 4%;
  background-color: #d6ac85;
}

.remaindiv {
  display: flex;
  width: 96%;
  height: 100%;
  flex-direction: column;
}

.arrowmaindiv {
  height: 14%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.arrowdiv {
  height: 100%;
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f4771;
  box-shadow: 0px 3px 6px #00000052;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.arrowdiv2 {
  height: 100%;
  width: 100%;
  position: absolute;
  top: -100%;
  right: -100%;
}

.messsagefromclien {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.messageheder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.underline {
  width: 20%;
  margin-top: 8px;
  border-bottom: 1px solid #d6ac85;
}

.msg-icn {
  display: inline-block;
  position: relative;
  padding: 20px 20px;
  color: #000;
  width: 90%;
  margin-bottom: 10px;
  box-sizing: border-box;
  /* max-width: 300px;
  min-width: 80px; */
  min-height: 38px;
}
.msg-icn:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #1f4771;
  border-radius: 2px;
  box-sizing: border-box;
  clip-path: polygon(
    0% 105%,
    0% 0%,
    105% 0%,
    105% 105%,
    93px 105%,
    93px 80%,
    71px 80%,
    71px 105%
  );
}

/* -1px -2px 0 #fff, -1px -2px 0 #1f4771; */
.msg-icn:after {
  content: "";
  position: absolute;
  display: block;
  width: 25px;
  height: 20px;
  background: #1f4771;
  top: calc(100% - 1px);
  left: 70px;
  box-sizing: border-box;
  clip-path: polygon(
    0 0,
    0% 100%,
    100% 0%,
    calc(100% - 2px) 0,
    1px calc(100% - 2px),
    1px 0
  );
}

.forwardbackward {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  border: 1px solid #1f4771;
  cursor: pointer;
}

.carousal {
  width: 100%;
  height: 100%;
}

.buttun {
  height: 30px !important;
  width: 30px !important;
  min-width: 0 !important;
  border-radius: 15px !important;
  background-color: #0000 !important;
  border: 1px solid #1f4771 !important;
}
.slider12 {
  display: flex !important;
  justify-content: center !important;
  /* align-items: center !important; */
}

.profilecmt {
  margin-left: 20px;
}

.fisrtSlider {
  display: flex;
  flex-direction: column !important;
}

.imagediv {
  display: flex;
  height: 100%;
  width: 60%;
}

.relativediv {
  display: flex;
  height: 100%;
  width: 40%;
}

.shadow {
  font-size: 30px;
  text-shadow: -1px -1px 0 #fff, -2px -2px 0 #1f4771;
}

.avatarUI {
  height: 50px !important;
  width: 50px !important;
  margin-left: 2px !important;
}

.sliderimg {
  height: 500px;
  width: 100%;
  border-radius: 4px;
}

.sliderscroll {
  overflow: auto;
}

.sliderscroll::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .maindiv {
    position: unset;
    height: 100%;
  }
  .arrowdiv {
    margin-right: 55px;
    height: 30px;
    width: 30px;
  }
  .arrowdiv2 {
    margin-right: 0px;
  }
  .secondGrid {
    align-items: center;
  }
  .remaindiv {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .buttun {
    height: 25px !important;
    width: 25px !important;
    border-radius: 13px !important;
    padding: 0px !important;
  }
  .avatarUI {
    height: 40px !important;
    width: 40px !important;
    margin-left: 2px !important;
  }
}

@media only screen and (max-width: 900px) {
  .sliderimg {
    height: 380px;
  }
}
