.radiomain {
  border: 1px solid #1f4771;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.inputtext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  background-color: #1f4771;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.inputside {
  background-color: #1f4771;
  padding: 10px;
  color: #ffffff;
  font-family: PoppinsRegular;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  margin-right: -14px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxmain {
  display: flex;
  align-items: center;
  margin-left: -9px;
}

.selextedlocationmain {
  padding: 6px 10px 6px 10px;
  border: 1px solid #1f4771;
  border-radius: 4px;
  margin: 14px 20px 0px 0px;
  position: relative;
}

.closebtn {
  height: 20px !important;
  width: 20px !important;
  border-radius: 10px !important;
  min-width: 0px !important;
  padding: 0px !important;
  position: absolute !important;
  top: -10px;
  right: -10px;
}

.socialmain {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.detailmain {
  padding: 10px 0px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
}

.dividermain {
  display: flex;
  padding: 10px 0px;
  align-items: center;
  flex-direction: column !important;
}

.divider {
  height: 40%;
  border-left: 1px solid #d3dbe5;
}

.lastfirst {
  display: flex;
}

.datepicker {
  background-color: #1f4771;
  padding: 10px;
  color: #ffffff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  margin-right: -14px;
  cursor: pointer;
}

.pickerStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
}

.loginText {
  display: flex;
  align-items: center;
  margin-top: 15px !important;
}

@media only screen and (max-width: 600px) {
  .dividermain {
    flex-direction: row !important;
    justify-content: space-around;
    padding: 10px 0px 20px;
  }
  .divider {
    height: 0px;
    width: 40%;
    border-bottom: 1px solid #d3dbe5;
  }
}

@media only screen and (max-width: 900px) {
  .lastfirst {
    margin-bottom: 16px !important;
  }
}

@media only screen and (max-width: 425px) {
  .loginText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnStyle {
    display: flex;
    align-items: center;
  }
}
