.divider1 {
  width: 50%;
  border-bottom: 1px solid #d3dbe5;
}

.secondGrid1 {
  /* height: 130px; */
  display: flex;
  padding: 10px 14px;
  /* border-radius: 4px; */
  flex-direction: column;
  /* border: 1px solid #1f4771; */
}

.thirdmain {
  margin-bottom: 20px !important;
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid #1f4771;
}

.imgmain2 {
  width: 20%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
}

.imageicon {
  height: 60px;
  width: 60px;
}

.textmain2 {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

.loginText {
  display: flex;
  align-items: center;
  margin-top: 15px !important;
  margin-left: 32px !important;
}

.singleDetailContainer {
  height: 100%;
  border: 1px solid #1f4771;
  border-radius: 4px;
}

@media only screen and (max-width: 599px) {
  .singleDetailContainer {
    height: auto;
    border: 1px solid #1f4771;
    border-radius: 4px;
  }
}

@media only screen and (max-width: 425px) {
  .loginText {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px !important;
  }
}
