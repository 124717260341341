// Query Sizes
$screen-sm: 425px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

.main_body_con {
  width: 100%;
  display: flex;
}

.common_container {
  width: 100%;

  @media screen and (min-width: $screen-sm) {
    padding: 0 5%;
  }

  @media screen and (min-width: $screen-xl) {
    padding: 0 10%;
  }
}

.fontsize {
  font-size: 35px !important;
  font-family: PoppinsSemiBold !important;

  @media screen and (max-width: $screen-md) {
    font-size: 24px !important;
  }
  @media screen and (max-width: $screen-sm) {
    font-size: 22px !important;
  }
}

.quizQuestionStyle {
  font-size: 18px !important;
}

.welcomefont {
  font-size: 50px !important;
  font-family: PoppinsSemiBold !important;

  @media screen and (max-width: $screen-md) {
    font-size: 30px !important;
  }
  // @media screen and (max-width: $screen-sm) {
  //   font-size: 22px !important;
  // }
}

.fontsize2 {
  font-size: 24px !important;
  font-family: PoppinsSemiBold !important;

  @media screen and (max-width: $screen-sm) {
    font-size: 18px !important;
  }
}

.mediumsemiboldfont {
  font-size: 20px !important;
  font-family: PoppinsSemiBold !important;
  @media screen and (max-width: $screen-md) {
    font-size: 16px !important;
  }
}

.semibold18 {
  font-size: 18px !important;
  font-family: PoppinsSemiBold !important;
}

.smallsemiboldfont {
  font-size: 14px !important;
  font-family: PoppinsSemiBold !important;
}

.mediumfont {
  font-size: 16px !important;
  font-family: PoppinsMedium !important;
  @media screen and (max-width: $screen-md) {
    font-size: 12px !important;
  }
}

.mediumlargefont {
  font-size: 20px !important;
  font-family: PoppinsMedium !important;

  @media screen and (max-width: $screen-md) {
    font-size: 18px !important;
  }
}

.regulerfont {
  font-size: 14px !important;
  font-family: PoppinsRegular !important;
}

.regulerlargefont {
  font-size: 18px !important;
  font-family: PoppinsRegular !important;

  @media screen and (max-width: $screen-md) {
    font-size: 14px !important;
  }
}

.mediumregulerfont {
  font-size: 24px !important;
  font-family: PoppinsRegular !important;

  @media screen and (max-width: $screen-md) {
    font-size: 20px !important;
  }
}

.reguler20 {
  font-size: 20px !important;
  font-family: PoppinsRegular !important;
}

.largerregulerfont {
  font-size: 36px !important;
  font-family: PoppinsRegular !important;

  @media screen and (max-width: $screen-md) {
    font-size: 25px !important;
  }
}

.regulersmallfont {
  font-size: 12px !important;
  font-family: PoppinsRegular !important;
}

.primarycolor {
  color: #1f4771;
}

.lightsilvercolor {
  color: #5a5a5a;
}

@media screen and (max-width: $screen-md) {
  input {
    font-size: 16px !important;
  }

  input:focus {
    font-size: 16px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pac-container {
  z-index: 2000 !important;
}
