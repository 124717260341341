* {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

body::-webkit-scrollbar {
  display: none;
}

i {
  color: #d3d3d3;
}

.MT60 {
  margin-top: 60px;
  padding: 16px 16px;
}

@font-face {
  font-family: "PoppinsBlack";
  src: local("PoppinsBlack"),
    url("./Assets/fonts/PoppinsBlack.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "PoppinsBlackItalic";
  src: local("PoppinsBlackItalic"),
    url("./Assets/fonts/PoppinsBlackItalic.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./Assets/fonts/PoppinsBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "PoppinsBoldItalic";
  src: local("PoppinsBoldItalic"),
    url("./Assets/fonts/PoppinsBoldItalic.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "PoppinsExtraBold";
  src: local("PoppinsExtraBold"),
    url("./Assets/fonts/PoppinsExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "PoppinsExtraBoldItalic";
  src: local("PoppinsExtraBoldItalic"),
    url("./Assets/fonts/PoppinsExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "PoppinsExtraLight";
  src: local("PoppinsExtraLight"),
    url("./Assets/fonts/PoppinsExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "PoppinsExtraLightItalic";
  src: local("PoppinsExtraLightItalic"),
    url("./Assets/fonts/PoppinsExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "PoppinsItalic";
  src: local("PoppinsItalic"),
    url("./Assets/fonts/PoppinsItalic.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./Assets/fonts/PoppinsLight.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PoppinsLightItalic";
  src: local("PoppinsLightItalic"),
    url("./Assets/fonts/PoppinsLightItalic.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./Assets/fonts/PoppinsMedium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsMediumItalic";
  src: local("PoppinsMediumItalic"),
    url("./Assets/fonts/PoppinsMediumItalic.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsSemiBoldItalic";
  src: local("PoppinsSemiBoldItalic"),
    url("./Assets/fonts/PoppinsSemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsThinItalic";
  src: local("PoppinsThinItalic"),
    url("./Assets/fonts/PoppinsThinItalic.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./Assets/fonts/PoppinsRegular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./Assets/fonts/PoppinsSemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsThin";
  src: local("PoppinsThin"),
    url("./Assets/fonts/PoppinsThin.ttf") format("truetype");
  font-weight: 100;
}
